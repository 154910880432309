import Vue from "vue";
import VueRouter from "vue-router";

//防止重复点击链接报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    name: "sensitiveTaboo",
    path: "/sensitiveTaboo",
    component: () => import("@/views/sensitiveTaboo/index.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("@/views/index/index.vue"),
    children: [
      {
        path: "/notFound",
        name: "notFound",
        component: () => import("@/views/notFound/index.vue"),
        meta: { toolTip: "404" },
      },
      {
        name: "apkManagement",
        path: "appManagement/apkManagement",
        component: () =>
          import("@/views/business/appManagement/apkManagement/index.vue"),
        meta: { toolTip: "应用管理 > 应用管理" },
      },
      {
        name: "categoryManagement",
        path: "appManagement/categoryManagement",
        component: () =>
          import("@/views/business/appManagement/categoryManagement/index.vue"),
        meta: { toolTip: "应用管理 > 分类管理" },
      },
      {
        name: "labelManagement",
        path: "appManagement/labelManagement",
        component: () =>
          import("@/views/business/appManagement/labelManagement/index.vue"),
        meta: { toolTip: "应用管理 > 标签管理" },
      },
      {
        name: "developerList",
        path: "developer/developerList",
        component: () =>
          import("@/views/business/developer/developerList/index.vue"),
        meta: { toolTip: "开发者管理 > 开发者列表" },
      },
      {
        name: "verifiedCheck",
        path: "developer/verifiedCheck",
        component: () =>
          import("@/views/business/developer/verifiedCheck/index.vue"),
        meta: { toolTip: "开发者管理 > 实名认证审核" },
      },
      {
        name: "informationCheck",
        path: "developer/informationCheck",
        component: () =>
          import("@/views/business/developer/informationCheck/index.vue"),
        meta: { toolTip: "开发者管理 > 开发者资料审批" },
      },
      {
        name: "contractManage",
        path: "developer/contractManage",
        component: () =>
          import("@/views/business/developer/contractManage/index.vue"),
        meta: { toolTip: "开发者管理 > 合同管理" },
      },
      {
        name: "financeInfoCheck",
        path: "developer/financeInfoCheck",
        component: () =>
          import("@/views/business/developer/financeInfoCheck/index.vue"),
        meta: { toolTip: "开发者管理 > 财务审核" },
      },
      {
        name: "putinDeveloperList",
        path: "developer/putinDeveloperList",
        component: () =>
          import("@/views/business/developer/putinDeveloperList/index.vue"),
        meta: { toolTip: "开发者管理 > 投放开发者列表" },
      },
      {
        name: "agentDeveloperList",
        path: "developer/agentDeveloperList",
        component: () =>
          import("@/views/business/developer/agentDeveloperList/index.vue"),
        meta: { toolTip: "开发者管理 > 代理商开发者列表" },
      },
      {
        name: "putinVerifiedCheck",
        path: "developer/putinVerifiedCheck",
        component: () =>
          import("@/views/business/developer/putinVerifiedCheck/index.vue"),
        meta: { toolTip: "开发者管理 > 投放平台实名认证审核" },
      },
      {
        name: "agentVerifiedCheck",
        path: "developer/agentVerifiedCheck",
        component: () =>
          import("@/views/business/developer/agentVerifiedCheck/index.vue"),
        meta: { toolTip: "开发者管理 > 代理商平台实名认证审核" },
      },
      {
        name: "billingCheck",
        path: "marketPromote/billingCheck",
        component: () =>
          import("@/views/business/marketPromote/billingCheck/index.vue"),
        meta: { toolTip: "市场推广 > 开票审核" },
      },
      {
        name: "rechargeCheck",
        path: "marketPromote/rechargeCheck",
        component: () =>
          import("@/views/business/marketPromote/rechargeCheck/index.vue"),
        meta: { toolTip: "市场推广 > 充值审核" },
      },
      {
        name: "activityPolicy",
        path: "marketPromote/activityPolicy",
        component: () =>
          import("@/views/business/marketPromote/activityPolicy/index.vue"),
        meta: { toolTip: "市场推广 > 激励政策" },
      },
      {
        name: "activityConfig",
        path: "marketPromote/activityConfig",
        component: () =>
          import("@/views/business/marketPromote/activityConfig/index.vue"),
        meta: { toolTip: "市场推广 > 激励配置" },
      },
      {
        name: "originality",
        path: "marketPromote/originality",
        component: () =>
          import("@/views/business/marketPromote/originality/index.vue"),
        meta: { toolTip: "市场推广 > 创意审核" },
      },
      {
        name: "priceThreshold",
        path: "marketPromote/priceThreshold",
        component: () =>
          import("@/views/business/marketPromote/priceThreshold/index.vue"),
        meta: { toolTip: "市场推广 > 出价阈值" },
      },
      {
        name: "commerce",
        path: "appCheck/commerce",
        component: () => import("@/views/business/appCheck/commerce/index.vue"),
        meta: { toolTip: "应用审核 > 商务确认" },
      },
      {
        name: "commercePutinCheckApp",
        path: "appCheck/commercePutinCheckApp",
        component: () =>
          import("@/views/business/appCheck/commercePutinCheckApp/index.vue"),
        meta: { toolTip: "应用审核 > 投放平台应用审核" },
      },
      {
        name: "infoCheck",
        path: "appCheck/infoCheck",
        component: () =>
          import("@/views/business/appCheck/infoCheck/index.vue"),
        meta: { toolTip: "应用审核 > 信息审核" },
      },
      {
        name: "claim",
        path: "appCheck/claim",
        component: () => import("@/views/business/appCheck/claim/claim.vue"),
        meta: { toolTip: "应用审核 > 认领审核" },
      },
      {
        name: "versionCheck",
        path: "appCheck/versionCheck",
        component: () =>
          import("@/views/business/appCheck/versionCheck/index.vue"),
        meta: { toolTip: "应用审核 > 版本审核" },
      },
      {
        name: "appointment",
        path: "appService/appointment",
        component: () =>
          import("@/views/business/appService/appointment/index.vue"),
        meta: { toolTip: "应用服务 > 预约管理" },
      },
      {
        name: "closedBeta",
        path: "appService/closedBeta",
        component: () =>
          import("@/views/business/appService/closedBeta/index.vue"),
        meta: { toolTip: "应用服务 > 内测管理" },
      },
      {
        name: "starting",
        path: "appService/starting",
        component: () =>
          import("@/views/business/appService/starting/index.vue"),
        meta: { toolTip: "应用服务 > 首发管理" },
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/index/index.vue"),
    children: [
      {
        name: "commerceManagement",
        path: "setCenter/commerceManagement",
        component: () =>
          import("@/views/system/setCenter/commerceManagement/index.vue"),
        meta: { toolTip: "系统管理 > 设置中心 > 商务人员管理" },
      },
      {
        name: "roleManagement",
        path: "setCenter/roleManagement",
        component: () =>
          import("@/views/system/setCenter/roleManagement/index.vue"),
        meta: { toolTip: "系统管理 > 设置中心 > 角色管理" },
      },
      {
        name: "userManagement",
        path: "setCenter/userManagement",
        component: () =>
          import("@/views/system/setCenter/userManagement/index.vue"),
        meta: { toolTip: "系统管理 > 设置中心 > 用户管理" },
      },
      // {
      //   path: "setCenter/authorityManagement",
      //   component: () =>
      //     import("@/views/system/setCenter/authorityManagement/index.vue"),
      //   meta: { toolTip: "系统管理 > 设置中心 > 权限管理" },
      // },
      // {
      //   path: "setCenter/dataDictionary",
      //   component: () =>
      //     import("@/views/system/setCenter/dataDictionary/index.vue"),
      //   meta: { toolTip: "系统管理 > 设置中心 > 数据字典" },
      // },
      // {
      //   path: "logManagement/logList",
      //   component: () =>
      //     import("@/views/system/logManagement/logList/index.vue"),
      //   meta: { toolTip: "系统管理 > 日志管理 > 日志列表" },
      // },
    ],
  },
  {
    path: "/",
    redirect: "/business",
  },
  {
    path: "*",
    redirect: "/notFound",
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
